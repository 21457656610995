import React from 'react';
import { Link } from 'gatsby';

function CallToAction(props) {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120">
            <div className="container pt-4 pt-md-0 pb-4 pb-md-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h2>Are you ready to experience the Litija’s unique underground?</h2>
                            <p className="cta-description mt--60 mb-0 font-weight-normal text-white">Tours are available all days of the week by prior arrangement for a minimum of 4 visitors.</p>
                            <p className="cta-description mb-0 font-weight-normal text-white">Without an appointment, you can visit the Export Pit every Saturday at 10:00.</p>
                            <Link className="btn-default btn-border btn-large btn-white mt--40" to="/en/price-list">View the price list</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CallToAction;